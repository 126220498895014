/**
 * Helper functions for adding folder (and subfolders) to the repository.
 */
import { FileWithPath } from 'react-dropzone';

interface FolderNode<T> {
    name: string;
    parentPath: string;
    data?: T;
    children?: Record<string, FolderNode<T>>;
}

export interface LocalFolderNode extends FolderNode<LocalFolder> {

}

export type LocalFolder = {
    path: string
    files: FileWithPath[]
}

/**
 * Groups files by folder as LocalFolder objects. This is only done if a folder 
 * was added by the user.
 * 
 * @param files an array of files
 * @returns a array of local folders
 */
export const getLocalFolders = (files: FileWithPath[]) : LocalFolder[] => {

    const result = Object.groupBy(files, file => file?.path.replace(file.name, ""));
    return Object.entries(result).map(f => {
        if (f[0].length !== 0) {
            return {
                path: f[0].substring(0, f[0].length - 1),
                files: f[1]
            } as LocalFolder;
        }
    }).filter(f => f !== undefined) || [];
}

/**
 * Transforms the list of local folders to a hierarchy. It also make sure that
 * intermediate levels are created.
 * 
 * @param localFolders the list of local folders
 * @returns the root of the local folder hierarchy
 */
function buildFolderHierarchy<T>(localFolders: LocalFolder[]): FolderNode<T> {
    const root: FolderNode<T> = { name: "root", children: {}, parentPath: "" };

    localFolders.forEach((localFolder) => {
        const parts = localFolder.path.split('/').filter((part) => part?.trim() !== '');
        let current = root;

        parts.forEach((part, partIndex) => {
            if (!current.children) {
                current.children = {};
            }

            if (!current.children[part]) {
                const previousParts = parts.slice(0, partIndex);
                const parentPath = previousParts?.length > 0 ? `/${previousParts?.join('/')}` : "";
                current.children[part] = { name: part, children: {}, parentPath };
            }

            current = current.children[part];

            if (partIndex == parts.length - 1) {
                current.data = localFolder as T;
            }
        });
    });

    return root;
}
/**
 * Traverses the folder hierarchy created by the buildFolderHierarchy() function. 
 * It first executes the callback on the current node and if the current node has 
 * children, recursively walk through each child.
 * 
 * @param node the current node
 * @param callback the callback function performing the desired action
 * @param depth the current dept in the hierarchy
 */
function walkFolderHierarchy<T>(
    node: FolderNode<T>,
    callback: (node: FolderNode<T>, depth: number) => Promise<FolderNode<T>>,
    depth: number = 0
): void {

    callback(node, depth).then(node => {
        if (node.children) {
            for (const key in node.children) {
                if (node.children.hasOwnProperty(key)) {
                    walkFolderHierarchy(node.children[key], callback, depth + 1);
                }
            }
        }
    })
}

/**
 * Traverses through the folder hierarchy and call the callback function for each 
 * item. First the list is transformed to a hierarchy.
 * 
 * @param localFolders  the list of local folder.
 * @param callback the callback function doing all the work
 */
export async function walkLocalFolderHierarchy<T>(
    localFolders: LocalFolder[],
    callback: (node: FolderNode<T>, depth: number) => Promise<FolderNode<T>>,
): Promise<void> {
    await walkFolderHierarchy(buildFolderHierarchy(localFolders), callback);
}