import { Folder } from "../types/Folder";
import * as ACData from "adaptivecards-templating";
import {
    dialog,
    tasks,
    AdaptiveCardDialogInfo,
    DialogDimension,
} from "@microsoft/teams-js";
import { FileStat } from 'webdav';

type PropertiesReturn = {
    openFolderPropertiesDialog: (folder: Folder, submitHandler: dialog.DialogSubmitHandler) => void;
    openEditDocumentDialog: (document: FileStat, onSubmit: (result: dialog.ISdkResponse) => void) => void;
}

const templatePayload = {
    "type": "AdaptiveCard",
    "$schema": "http://adaptivecards.io/schemas/adaptive-card.json",
    "version": "1.5",
    "body": [
        {
            "type": "Container",
            "items": [
                {
                    "type": "Input.Text",
                    "placeholder": "Enter the folder name",
                    "id": "title",
                    "value": "${title}",
                    "label": "Title",
                    "maxLength": 255,
                    "isRequired": true,
                    "errorMessage": "This is a required value"
                }
            ]
        },
        {
            "type": "ActionSet",
            "actions": [
                {
                    "type": "Action.Submit",
                    "title": "Save",
                    "id": "propertiesSubmit",
                    "tooltip": "Save the folder properties"
                }
            ]
        }
    ]
};

const documentPayload = {
    "type": "AdaptiveCard",
    "$schema": "http://adaptivecards.io/schemas/adaptive-card.json",
    "version": "1.2",
    "body": [
        {
            "type": "ActionSet",
            "actions": [
                {
                    "type": "Action.OpenUrl",
                    "title": "Action.OpenUrl",
                    "url": "${url}"
                }
            ]
        }
    ]
}

const usePropertiesCard = (): PropertiesReturn => {

    const openFolderPropertiesDialog = (folder: Folder, submitHandler: dialog.DialogSubmitHandler) => {

        var template = new ACData.Template(templatePayload);

        var card = template.expand({ $root: { ...folder } });

        let cardInfo: AdaptiveCardDialogInfo = {
            card: JSON.stringify(card),
            size: {
                height: 500,
                width: 1200
            },
            title: "Folder Properties",
        }
        
//        dialog.adaptiveCard.open(cardInfo, submitHandler);
        tasks.startTask({
            card: JSON.stringify(card)
        })
    }

    const openEditDocumentDialog = (document: FileStat, onSubmit: (result: dialog.ISdkResponse) => void) => {

        const context = {
            subEntityId: document.filename
        };

        const encodedContext: string = encodeURIComponent(JSON.stringify(context));
        const baseUrl = window.location.origin + (process.env.REACT_APP_ROUTER_BASE || "");

        dialog.url.open({
            url: `${baseUrl}/index.html#/edit?context=${encodedContext}`,
            title: "Document online bewerken",
            size: {
                height: DialogDimension.Small,
                width: DialogDimension.Medium
            }
        }, onSubmit
        );
    }

    return { openFolderPropertiesDialog, openEditDocumentDialog };
};

export default usePropertiesCard;