/**
 * This is a dialog where the user can select files, either by opening the file
 * selection dialog or by dragging files to the dropzone. Most of the work is 
 * done by the 'react-dropzone' hook. If a selection is made then the documents 
 * and folder can be added to the repository.
 */
import { useState, useContext, useEffect } from 'react';
import { useDropzone, FileWithPath } from 'react-dropzone';
import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent,
    Card,
    CardHeader,
    Caption1,
    Button,
    Text,
    makeStyles,
    shorthands,
    tokens,
    Tooltip,
} from "@fluentui/react-components";

import {
    SubtractCircle24Regular,
    CloudArrowUp32Regular,
    CheckmarkCircle24Regular,
    ErrorCircle24Regular
} from "@fluentui/react-icons";

import { getFileTypeIconProps, FileIconType } from '@fluentui/react-file-type-icons';
import { Icon } from '@fluentui/react/lib/Icon';
import { formatDateTime, formatSize } from '../../utils/formattingUtils';
import { FolderContext } from "../../context/FolderContextProvider";
import { AddDocumentResponse, FolderContextType } from "../../types/Folder";
import { LocalFolder, LocalFolderNode, getLocalFolders, walkLocalFolderHierarchy } from '../../types/LocalFolder';

type AddDocumentsDialogProps = {
    isOpen: boolean,
    onClose: () => void,
    onFilesAdded: () => void
}

type AddFileState = {
    value: "pending" | "added" | "error",
    message?: string
}

type FileCardProps = {
    file: FileWithPath,
    state: AddFileState,
    onRemoveFile: (file: FileWithPath) => void
}

type FolderCardProps = {
    folder: LocalFolder,
    state: AddFileState,
    onRemoveFolder: (file: LocalFolder) => void
}

type CardActionProps = {
    fileOrFolder: LocalFolder | FileWithPath,
    state: AddFileState,
    onRemove: (fileOrFolder: LocalFolder | FileWithPath) => void
}

const commonStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%s",
    height: "100px",
    cursor: "pointer",
};

const useStyles = makeStyles({
    dropzoneNormalStyle: {
        ...commonStyles,
        ...shorthands.border("2px", "dashed", tokens.colorNeutralForeground1),
        backgroundColor: tokens.colorNeutralBackground2,
    },
    dropzoneOverStyle: {
        ...commonStyles,
        ...shorthands.border("2px", "dashed", tokens.colorNeutralForeground1Hover),
        backgroundColor: tokens.colorNeutralBackground2Hover,
    },
    cardsGrid: {
        ...shorthands.gap("16px"),
        display: "flex",
        flexDirection: "column",
    },
    card: {
        width: "100%",
        height: "fit-content"
    },
    caption: {
        color: tokens.colorNeutralForeground3,
    },
});

const getFileDescription = (file: FileWithPath) => {
    var pathInfo = '';
    if (file.path) {
        if (file.path !== file.name) {
            pathInfo = ` | Pad: ${file.path.replace('/' + file.name, '')}`;
        }
    }
    return `${formatSize(file.size)} | Gewijzigd: ${formatDateTime(file.lastModified)}${pathInfo}`;
}

const getFolderDescription = ({ files }: LocalFolder, truncate: boolean) => {
    const filenames = files.map(file => file.name).join(", ");
    if (truncate) return safeTruncate(filenames, 300);
    return filenames;
}

const safeTruncate = (str: string, length: number, ending = '...') => {
    if (str.length <= length) return str;
    return str.slice(0, length - ending.length) + ending;
}

const getFileKey = (file: (FileWithPath | LocalFolder)) => {
    return file.path ? file.path : (file as FileWithPath).name;
}

const getNewFileState = (result: AddDocumentResponse): AddFileState => {
    switch (result.response) {
        case 'ok':
            return { value: "added", message: "Toegevoegd" };
        case 'error':
            return { value: "error", message: result.message };
        case 'exists':
            return { value: "error", message: `${result.file.name} bestaat al` };
    }
}

function getNewLocalFolderState(results: AddDocumentResponse[]): AddFileState {

    if (results.every(result => result.response === 'ok')) {
        return { value: "added", message: "Toegevoegd" };
    }

    return {
        value: "error", message:
            joinWithAnd(results.filter(result => result.response === 'exists').map(result => result.file.name),
                "bestaat al. ", "bestaan al. ") +
            joinWithAnd(results.filter(result => result.response === 'error').map(result => result.file.name),
                "kon niet worden toegevoegd.", "konden niet worden toegevoegd.")
    }
}

const joinWithAnd = (filenames: string[], messageSingle: string, messagePlural: string): string => {

    if (filenames.length === 0) {
        return '';
    }

    if (filenames.length === 1) {
        return `Document ${filenames[0]} ${messageSingle}`;
    }
    if (filenames.length === 2) {
        return `Documenten ${filenames.join(' en ')} ${messagePlural}`;
    }
    const last = filenames.pop();
    return `Documenten ${filenames.join(', ')} en ${last} ${messagePlural}`;
}

export default function AddDocumentsDialog({ isOpen, onClose, onFilesAdded }: AddDocumentsDialogProps) {

    const styles = useStyles();

    /**
     * Holds the list and the state of currently selected files.
     */
    const [files, setFiles] = useState<(FileWithPath | LocalFolder)[]>([]);
    const [addFileState, setAddFileState] = useState<Map<string, AddFileState>>(new Map);
    const [adding, setAdding] = useState(false);

    const getFileState = (file: (FileWithPath | LocalFolder)) => {
        return addFileState.get(getFileKey(file));
    }

    /**
     * Update the selected files on selection or drop.
     * @param acceptedFiles the selected files
     */
    const onDrop = (acceptedFiles: FileWithPath[]) => {

        setAdding(false);

        const filteredAcceptedFiles: (FileWithPath | LocalFolder)[] = [
            ...getLocalFolders(acceptedFiles), ...acceptedFiles.filter(file => file.name === file?.path)
        ].filter(fileOrFolder => !addFileState.has(getFileKey(fileOrFolder)));

        setFiles(previousFiles => [
            ...previousFiles,
            ...filteredAcceptedFiles
        ]);
    };

    /**
     * Updates the state of the selected files.
     */
    useEffect(() => {
        var newMap = new Map<string, AddFileState>();
        files.forEach(file => {
            const state = getFileState(file);
            newMap.set(getFileKey(file), state ? state : { value: "pending" })
        })
        setAddFileState(newMap);
    }, [files]);

    /**
     * Initialize the drop zone variables
     */
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    /**
     * This method is called by the FileCard component when a file should by
     * removed from the list.
     * @param filename the name of the file to remove.
     */
    const onRemoveItem = (deletedFile: (FileWithPath | LocalFolder)) => {
        const deleteFileKey = getFileKey(deletedFile);
        setFiles(files => files.filter(file => deleteFileKey !== getFileKey(file)));
    };

    const { folder, addDocument, createFolder } = useContext(FolderContext) as FolderContextType;

    /**
     * Add the selected files and folders to the repository.
     * 
     * @param files combined list of files and folders.
     */
    const onAddSelectedFiles = (files: (FileWithPath | LocalFolder)[]) => {

        setAdding(true);

        const filesWithPath = files.filter(file => addFileState.get(getFileKey(file)).value === "pending")
            .filter(file => file instanceof File);

        if (filesWithPath.length > 0) {
            addFilesWithPath(filesWithPath, folder.path, true);
        }

        const localFolders = files.filter(file => addFileState.get(getFileKey(file)).value === "pending")
            .filter(file => !(file instanceof File)) as LocalFolder[];

        if (localFolders.length > 0) {
            walkLocalFolderHierarchy(localFolders, addLocalFolderCallback);
        }
    }

    /**
     * Adds the items of the type FileWithPath to the repository. The files are
     * added after each other. The callback function is only called when all 
     * the files are added.
     * 
     * @param files the files
     * @param parentPath the parent folder of the files
     */
    const addFilesWithPath = async (files: FileWithPath[], parentPath: string, notify: boolean) => {

        let addDocumentResponses: AddDocumentResponse[] = [];

        for (let i = 0; i < files.length; ++i) {
            const file = files[i];
            const result = await addDocument({
                filename: `${parentPath}/${file.name}`,
                file
            });
            const fileKey = getFileKey(file);
            if (addFileState.has(fileKey)) {
                setAddFileState(prevState => {
                    var localNewMap = new Map<string, AddFileState>(prevState);
                    localNewMap.set(getFileKey(file), getNewFileState(result));
                    return localNewMap;
                });
            } else {
                addDocumentResponses.push(result);
            }
        }

        if (notify) {
            onFilesAdded();
        }
        return addDocumentResponses;
    }

    /**
     * Adds the select folders and files the repository. The method will continue
     * if a folder already exists. Note that not all nodes will contain files
     * because sometimes also an intermediate level should be created.
     * 
     * @param node the current node
     * @param depth the current depth in the hierarchy
     * @returns the current node
     */
    async function addLocalFolderCallback(node: LocalFolderNode, depth: number): Promise<LocalFolderNode> {
        if (node.name !== "root") {
            const fullParentPath = node.parentPath.length === 0 ? folder.path : `${folder.path}${node.parentPath}`;
            const result = await createFolder(fullParentPath, node.name);

            if (result.response !== 'error') {
                if (node?.data?.files) {
                    const responses = await addFilesWithPath(node.data.files, `${fullParentPath}/${node.name}`, false);
                    setAddFileState(updateAddFileState(getNewLocalFolderState(responses)));
                }
                if (depth === 1) {
                    onFilesAdded();
                }
            } else {
                setAddFileState(updateAddFileState({value: 'error', message: result?.message}));
            }
        }
        return node;

        function updateAddFileState(addFileState: AddFileState) {
            return prevState => {
                var localNewMap = new Map<string, AddFileState>(prevState);
                localNewMap.set(getFileKey(node.data),addFileState );
                return localNewMap;
            };
        }
    }

    const onCloseDialog = () => {
        setFiles([]);
        setAddFileState(new Map());
        onClose();
    }

    return (
        <Dialog open={isOpen}>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>Documenten toevoegen</DialogTitle>
                    <DialogContent>
                        <Text><p>In dit scherm kunnen losse documenten of de complete inhoud van mappen worden toegevoegd.</p></Text>
                        <div className={styles.cardsGrid}>
                            <div {...getRootProps()}
                                className={isDragActive ? styles.dropzoneOverStyle : styles.dropzoneNormalStyle}>
                                <input {...getInputProps()} />
                                <CloudArrowUp32Regular />
                                <Text>&nbsp;Klik hier of sleep de bestanden of mappen hier naartoe</Text>
                            </div>
                            {files.filter(file => file instanceof File).map(file =>
                                <FileCard file={file as FileWithPath} state={getFileState(file)} onRemoveFile={onRemoveItem} key={getFileKey(file)} />
                            )}
                            {files.filter(file => !(file instanceof File)).map(file =>
                                <FolderCard folder={file as LocalFolder} state={getFileState(file)} onRemoveFolder={onRemoveItem} key={getFileKey(file)} />
                            )}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button
                                appearance="secondary"
                                onClick={onCloseDialog}
                            >Sluiten</Button>
                        </DialogTrigger>
                        <Button
                            appearance="primary"
                            disabled={adding || !files.some(file => getFileState(file)?.value === "pending")}
                            onClick={() => {
                                onAddSelectedFiles([...files]);
                            }}
                        >Toevoegen</Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    )
}

const FileCard: React.FC<FileCardProps> = ({ file, state, onRemoveFile }) => {

    const styles = useStyles();

    return (
        <Card className={styles.card}
            floatingAction={<CardAction fileOrFolder={file} state={state} onRemove={onRemoveFile} />}
        >
            <CardHeader
                image={<Icon {...getFileTypeIconProps({ extension: file.name.substring(file.name.lastIndexOf(".") + 1), size: 32 })} />}
                header={<Text weight="semibold">{file.name}</Text>}
                description={
                    <Caption1 className={styles.caption}>{getFileDescription(file)}</Caption1>
                }
            />
        </Card>
    );
}

const FolderCard: React.FC<FolderCardProps> = ({ folder, state, onRemoveFolder }) => {

    const styles = useStyles();

    return (
        <Card className={styles.card}
            floatingAction={<CardAction fileOrFolder={folder} state={state} onRemove={onRemoveFolder} />}
        >
            <CardHeader
                image={<Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: 32 })} />}
                header={<Text weight="semibold">{folder.path}</Text>}
                description={
                    <Tooltip content={getFolderDescription(folder, false)} relationship="description">
                        <Caption1 className={styles.caption}>{getFolderDescription(folder, true)}</Caption1>
                    </Tooltip>
                }
            />
        </Card>
    );
}

const CardAction: React.FC<CardActionProps> = ({ fileOrFolder, state, onRemove }) => {
    return (<>
        {state?.value === 'added' ? (
            <Tooltip content={state?.message} relationship="label">
                <Button
                    appearance='transparent'
                    icon={<CheckmarkCircle24Regular />}
                />
            </Tooltip>
        ) : null}
        {state?.value === 'error' ? (
            <Tooltip content={state?.message} relationship="label">
                <Button
                    appearance='transparent'
                    icon={<ErrorCircle24Regular />}
                />
            </Tooltip>
        ) : null}
        <Tooltip content="Verwijderen uit lijst" relationship="label">
            <Button
                appearance='transparent'
                icon={<SubtractCircle24Regular />}
                onClick={() => onRemove(fileOrFolder)}
            />
        </Tooltip>
    </>)
}