import { useEffect, useState, useContext } from "react";
import { useSearchParams } from "react-router-dom";
import {
    Button,
    Text,
    Caption1,
    Card,
    CardHeader,
    CardFooter,
    MessageBar,
    MessageBarBody,
    Checkbox,
    makeStyles,
    tokens,
    shorthands
} from "@fluentui/react-components";
import {
    Open16Regular,
    SaveEditRegular,
} from "@fluentui/react-icons";
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import { Icon } from '@fluentui/react/lib/Icon';
import { dialog } from "@microsoft/teams-js";
import { EditDocumentInfo, FolderContextType } from "../../types/Folder";
import { FolderContext } from "../../context/FolderContextProvider";
import { TeamsFxContext } from "../../components/Context";

const openURI = (uri: string) => {
    function createIframe() {
        var iframe = document.createElement("iframe");
        iframe.style.visibility = 'hidden';
        iframe.style.display = 'none';
        iframe.src = uri;
        document.body.appendChild(iframe);
        setTimeout(dialog.url.submit, 100);
    }

    setTimeout(createIframe, 5);
}

type officeEditorInfoType = {
    uri: string,
    name: string
}

let officeEditorInfoMap: Map<string, officeEditorInfoType> = new Map();

officeEditorInfoMap.set("docx", { uri: "ms-word", name: "Word" });
officeEditorInfoMap.set("xlsx", { uri: "ms-excel", name: "Word" });
officeEditorInfoMap.set("pptx", { uri: "ms-powerpoint", name: "PowerPoint" });
officeEditorInfoMap.set("vsdx", { uri: "ms-visio", name: "Visio" });

const useStyles = makeStyles({
    card: {
        height: "fit-content",
        backgroundColor: tokens.colorNeutralBackground1
    },
    message: {
        width: "99% !important"
    },
    caption: {
        color: tokens.colorNeutralForeground3,
    },
    button: {
        ...shorthands.margin("5px")
    }
});

const getEditLink = (filename: string, officeEditorInfo: officeEditorInfoType) => {
    return `${officeEditorInfo?.uri}:${document.location.origin}${filename.replaceAll(' ', '%20')}`;
}

/**
 * Opens the link in a new window.
 * 
  * @param webUrl the web url
 */
const openEditLink = (webUrl: string) => {
    const element = document.createElement("a");
    element.href = webUrl;
    element.target = "_blank";
    document.body.appendChild(element);
    element.click();
    element.remove();

}

export default function EditDocumentDialog() {

    const styles = useStyles();

    const supportOfflineEditing = false;

    const { teamsUserCredential } = useContext(TeamsFxContext);
    const [shared, setShared] = useState(false);
    const [sharedEnabled, setSharedEnabled] = useState(false);
    const [editDocumentInfo, setEditDocumentInfo] = useState<EditDocumentInfo | null>(null);
    const [userId, setUserId] = useState<string | null>(null);

    /**
     * Fetch the filename from the context parameter and check the current editing 
     * state.
     */
    const [parameters] = useSearchParams();
    const [filename, setFilename] = useState("");

    /**
     * Edit the document in the online environment of Office
     */
    const { editDocument, saveDocument, getLockInfo } = useContext(FolderContext) as FolderContextType;

    /**
     * Fetch the identity of the user
     */
    useEffect( () => {
        teamsUserCredential.getUserInfo().then(async userInfo =>  {
            setUserId(userInfo.preferredUserName)
        })
    }, []);

    /**
     * Parses the input and check if there is already an editing reference for
     * this document.
     */
    useEffect( () => {

        const fetchLockInfo = async () => {
            const context = JSON.parse(parameters.get("context") || "{}");
            setFilename(context.subEntityId || "Geen context!"); // TODO disable if no context
            const editInfo = await getLockInfo(context.subEntityId, userId);
            setEditDocumentInfo(editInfo);
            setShared(editInfo.shared || !editInfo.locked);
            setSharedEnabled(!editInfo.locked);
        }
        
        fetchLockInfo();

    }, [userId]);

    /**
     * Starts the online editing session. The server will generate a editing url.
     * This url is opened in a new window and a local reference to the document is
     * kept in the local storage.
     * 
     * If the document already had a editing url then that url is reused to open the
     * document.
     * 
     * @param filename the filename
     */
    const startOfficeOnlineEditor = async (filename: string) => {

        if (!editDocumentInfo?.locked) {
            const editDocumentInfo = await editDocument({ filename, userId, shared });

            if (editDocumentInfo?.webUrl) {
                openEditLink(editDocumentInfo.webUrl);
                dialog.url.submit("start editing");
            } else {
                // TODO show error information
            }
        } else {
            if (editDocumentInfo.webUrl) {
                openEditLink(editDocumentInfo.webUrl);
                dialog.url.submit("already editing");
            }
        }
    }

    /**
     * Ends the offline editing session. The document is save to the content engine
     * and the local information is cleared.
     * 
     * @param filename the filename
     */
    const endOfficeOnlineEditor = async (filename: string) => {
        const saveDocumentInfo = await saveDocument({
            filename,
            userId,
            documentId: editDocumentInfo.documentId,
            shared
        });

        if (saveDocumentInfo.response === "ok") {
            dialog.url.submit("checked in");
        } else {
            // TODO show error message
        }
    }

    const title = filename.substring(filename.lastIndexOf("/") + 1);
    const path = filename.substring(0, filename.lastIndexOf("/"));
    const extension = filename.substring(filename.lastIndexOf(".") + 1);

    let officeEditorInfo: officeEditorInfoType | undefined;
    if (officeEditorInfoMap.has(extension)) {
        officeEditorInfo = officeEditorInfoMap.get(extension);
    }

    /**
     * Returns the message shown in the card body.
     * 
     * @param editing true, if we are already editing this document
     * @param officeEditorInfo the office editor info
     * @returns the editing message
     */
    const getEditingMessage = (editDocumentInfo: EditDocumentInfo, officeEditorInfo: officeEditorInfoType) => {
        if (!officeEditorInfo) {
            return "Voor dit formaat wordt bewerken nog niet ondersteund";
        }
        if (editDocumentInfo) {
            if (!editDocumentInfo.locked) {
                return `Het document wordt in een nieuw browser venster met ${officeEditorInfo.name} online geopend.`
            } else {
                if (editDocumentInfo.webUrl) {
                    return `Voordat het bewerken kan worden voltooid moet het bestand in ${officeEditorInfo.name} online worden afgesloten.`;
                } else {
                    return `Het document is buiten ${officeEditorInfo.name} online in bewerking en kan daarom niet worden bewerkt.`;
                }
            }
        }
    }

    /**
     * Edit buttons state
     */
    const canEditOnline = officeEditorInfo && editDocumentInfo &&
        (!editDocumentInfo.locked || editDocumentInfo.webUrl);

    const canFinishEditOnline = officeEditorInfo && editDocumentInfo && editDocumentInfo.webUrl;

    return (
        <>
            <Card size="large" appearance="subtle" className={styles.card}>
                <CardHeader
                    className={styles.caption}
                    image={<Icon {...getFileTypeIconProps({ extension, size: 48 })} />}
                    header={<Text weight="semibold">{title}</Text>}
                    description={<Caption1>{path}</Caption1>}
                ></CardHeader>
                {officeEditorInfo ? (
                    <Checkbox
                        disabled={!sharedEnabled}
                        checked={shared}
                        onChange={(ev, data) => setShared(data.checked as boolean)}
                        label="Anderen mogen dit document ook bewerken"
                    />) : null
                }
                <MessageBar key="info" intent="info" className={styles.message}>
                    <MessageBarBody>{getEditingMessage(editDocumentInfo, officeEditorInfo)}</MessageBarBody>
                </MessageBar>
                <CardFooter
                    action={
                        <>
                            <Button className={styles.button}
                                onClick={() => dialog.url.submit("canceled")}
                            >Annuleren</Button>
                            {canEditOnline ? (
                                <Button appearance={editDocumentInfo.webUrl ? "secondary" : "primary"}
                                    className={styles.button}
                                    onClick={() => startOfficeOnlineEditor(filename)}
                                    icon={<Open16Regular />}
                                >{`Online bewerken`}</Button>) : null
                            }
                            {canFinishEditOnline ? (
                                <Button appearance={editDocumentInfo.webUrl ? "primary" : "secondary"}
                                    className={styles.button}
                                    onClick={() => endOfficeOnlineEditor(filename)}
                                    icon={<SaveEditRegular />}
                                >{`Online bewerken voltooien`}</Button>) : null
                            }
                            {officeEditorInfo && supportOfflineEditing ? (
                                <Button appearance="primary"
                                    className={styles.button}
                                    onClick={() => openURI(getEditLink(filename, officeEditorInfo as officeEditorInfoType))}
                                    icon={<Open16Regular />}
                                >{`Bewerken`}</Button>) : null
                            }
                        </>}
                ></CardFooter>
            </Card>
        </>
    )
}