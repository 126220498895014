/**
 * This component is responsible for providing the WebDAVClient object. If this
 * object is not yet authenticated then a login dialog is displayed.
 */
import React, { createContext, useState, useEffect } from "react";
import { AuthType, createClient, WebDAVClient } from 'webdav';
import LoginDialog from "../components/dialogs/LoginDialog";
import {
    authentication
} from "@microsoft/teams-js";

export type WebDAVContextType = {
    client: WebDAVClient | null,
    initializeClient: () => void,
    resetClient: () => void
}

type WebDAVClientState = "initializing" | "authenticating" | "created" | "basic";

export const WebDAVContext = createContext<WebDAVContextType | null>(null);

/**
 * Creates the WebDAV client using the provided token.
 * 
 * @param authToken the token
 * @returns the WebDAV client object
 */
const createWebDAVClientWithToken = (authToken: string) => {
    return createClient(window.location.origin + "/zfc", {
        authType: AuthType.Token,
        token: {
            access_token: authToken,
            token_type: "JWS"
        }
    });
};

/**
 * Creates the WebDAV client using the username and password.
 * 
 * @param loginUserName the username
 * @param loginPassword the password
 * @returns the WebDAV client object
 */
const createWebDAVClientWithCredentials = (loginUserName: string, loginPassword: string) => {

    return createClient(window.location.origin + "/zfc", {
        username: loginUserName,
        password: loginPassword
    });
};

const WebDAVContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {

    const resetClient = () => {
        localStorage.removeItem("webdav_password");
        localStorage.removeItem("webdav_username");
        localStorage.removeItem("webdav_auth_token");
        initializeClient();
    }

    const initializeClient = () => {
        setWebDAVClientState("initializing");
    }

    const [webDAVContext, setWebDAVContext] = useState<WebDAVContextType>({
        client: null,
        initializeClient,
        resetClient
    });

    const [webDAVClientState, setWebDAVClientState] = useState<WebDAVClientState>("initializing");
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    /**
     * Checks if there is a token stored in local storage. If there is a token then
     * a check is made if the token is still valid. If this is the case then the token
     * is re-used. Otherwise the token is deleted and a new authentication flow is 
     * started.
     */
    useEffect(() => {

        if ( webDAVClientState === 'basic') {
            return;
        }

        const loginUserName = localStorage.getItem("webdav_username");
        const loginPassword = localStorage.getItem("webdav_password");

        if (loginUserName && loginPassword) {
            restoreClientWithPassword(loginUserName, loginPassword);
        } else {
            const authToken = localStorage.getItem("webdav_auth_token");
            if (authToken) {
                restoreClientWithToken(authToken);
            } else {
                setWebDAVClientState("authenticating");
            }
        }

        /**
         * Restores the client using the stored token.
         * 
         * @param authToken the token
         */
        function restoreClientWithToken(authToken: string) {
            const client = createWebDAVClientWithToken(authToken);
            client.exists("/").then(() => {
                setWebDAVContext({ ...webDAVContext, client });
                setErrorMessage(null);
                setWebDAVClientState("created");
            }).catch(reason => {
                localStorage.removeItem("webdav_auth_token");
                setWebDAVClientState("authenticating");
            });
        }

        /**
         * Restores the client using the store username and password.
         * 
         * @param loginUserName the username
         * @param loginPassword the password
         */
        function restoreClientWithPassword(loginUserName: string, loginPassword: string) {
            const client = createWebDAVClientWithCredentials(loginUserName, loginPassword);
            client.exists("/").then(() => {
                setWebDAVContext({ ...webDAVContext, client });
                setErrorMessage(null);
                localStorage.setItem("webdav_username", loginUserName);
                localStorage.setItem("webdav_password", loginPassword);
                setWebDAVClientState("created");
            }).catch( () => {
                localStorage.removeItem("webdav_password");
                localStorage.removeItem("webdav_username");
                setWebDAVClientState("authenticating");
            });
        }

    }, [webDAVClientState]);

    /**
     * This will start the authentication sequence described here, resulting in a authentication token:
     * 
     * https://learn.microsoft.com/en-us/microsoftteams/platform/tabs/how-to/authentication/auth-flow-tab
     */
    const onExternalAuthentication = () => {

        authentication.authenticate({
            url: `${window.location.origin}/webdav-auth-start.html`,
            height: 500
        }).then(async (authToken) => {

            localStorage.setItem("webdav_auth_token", authToken);
            const client = createWebDAVClientWithToken(authToken);
            setWebDAVContext({ ...webDAVContext, client });
            setWebDAVClientState("created");

        }).catch((result) => {

            if (result.message === 'CancelledByUser') {
                setErrorMessage("het aanmelden is onderbroken, probeer het nog een keer.");
            } else if (result.message === 'StateDoesNotMatch') {
                setErrorMessage("de server heeft een verkeerde status teruggegeven");
            } else {
                setErrorMessage(result?.message);
            }
        });
    }

    /**
     * Creates a WebDAV client using basic authentication.
     * 
     * @param loginUserName the username
     * @param loginPassword the password
     */
    const onBasicAuthentication = (loginUserName: string, loginPassword: string) => {
        const client = createWebDAVClientWithCredentials(loginUserName, loginPassword);
        client.exists("/").then(() => {
            setWebDAVContext({ ...webDAVContext, client });
            setWebDAVClientState("basic");
            localStorage.setItem("webdav_username", loginUserName);
            localStorage.setItem("webdav_password", loginPassword);
        }).catch(reason => {
            setErrorMessage(reason?.message); 
        });
    }

    /**
     * Handles the logon button. 
     * 
     * If the authentication is successful then a new WebDAV client is created and the state of
     * this component is set to "created".
     * 
     * If the was an error then the 
     */
    const onLoginClicked = (loginUserName: string, loginPassword: string, useExternalAuth: boolean) => {
        if (useExternalAuth) {
            onExternalAuthentication();
        } else {
            onBasicAuthentication(loginUserName,loginPassword);
        }
    }

    return (
        <WebDAVContext.Provider value={webDAVContext}>
            {
                (webDAVClientState === "created" || webDAVClientState === 'basic' ? (
                    children
                ) : (
                    (webDAVClientState === "authenticating" ? (
                        <LoginDialog onLoginClicked={onLoginClicked} errorMessage={errorMessage} />
                    ) : (
                        <p>Checking...</p>
                    )
                    )
                ))
            }
        </WebDAVContext.Provider>
    )
}

export default WebDAVContextProvider;