// https://fluentsite.z22.web.core.windows.net/quick-start
import {
  FluentProvider,
  teamsLightTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
  Spinner,
  tokens,
} from "@fluentui/react-components";
import { initializeFileTypeIcons } from '@fluentui/react-file-type-icons';
import { HashRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import Privacy from "./Privacy";
import TermsOfUse from "./TermsOfUse";
import BrowserTab from "./BrowserTab";
import PersonalBrowserTab from "./PersonalBrowserTab";
import { TeamsFxContext } from "./Context";
import config from "./sample/lib/config";
import EditDocumentDialog from "./dialogs/EditDocumentDialog";
import BrowserConfiguration from "./BrowserConfiguration";
import WebDAVContextProvider from "../context/WebDAVContext";
import FolderContextProvider from "../context/FolderContextProvider";
import { ErrorBoundary } from "react-error-boundary";

/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {
  const { loading, theme, themeString, teamsUserCredential } = useTeamsUserCredential({
    initiateLoginEndpoint: config.initiateLoginEndpoint!,
    clientId: process.env.REACT_APP_CLIENT_ID!,
  });

  initializeFileTypeIcons();

  return (
    <TeamsFxContext.Provider value={{ theme, themeString, teamsUserCredential }}>
      <FluentProvider
        theme={
          themeString === "dark"
            ? teamsDarkTheme
            : themeString === "contrast"
              ? teamsHighContrastTheme
              : {
                ...teamsLightTheme,
                colorNeutralBackground3: "#eeeeee",
              }
        }
        style={{ background: tokens.colorNeutralBackground3 }}
      >
        <Router>
          {loading ? (
            <Spinner style={{ margin: 100 }} />
          ) : (
            <Routes>
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/termsofuse" element={<TermsOfUse />} />
              <Route path="/edit/*" element={
                <WebDAVContextProvider>
                  <ErrorBoundary fallback={<div>Something went wrong</div>}>
                  <FolderContextProvider>
                    <EditDocumentDialog />
                  </FolderContextProvider>
                  </ErrorBoundary>
                </WebDAVContextProvider>} />
              <Route path="/configure" element={<WebDAVContextProvider><BrowserConfiguration /></WebDAVContextProvider>} />
              <Route path="/tab" element={<WebDAVContextProvider><PersonalBrowserTab /></WebDAVContextProvider>} />
              <Route path="/channeltab" element={<WebDAVContextProvider><BrowserTab /></WebDAVContextProvider>} />
              <Route path="*" element={<Navigate to={"/tab"} />}></Route>
            </Routes>
          )}
        </Router>
      </FluentProvider>
    </TeamsFxContext.Provider>
  );
}
