/**
 * Hook controlling the state of the add folder dialog.
 */
import { useState } from 'react';

type UseDialogReturn = {
  isOpen: boolean;
  openAddFolderDialog: () => void;
  closeAddFolderDialog: () => void;
}

const useAddFolderDialog = (): UseDialogReturn => {
  const [isOpen, setIsOpen] = useState(false);

  const openAddFolderDialog = () => setIsOpen(true);
  const closeAddFolderDialog = () => setIsOpen(false);

  return { isOpen, openAddFolderDialog, closeAddFolderDialog };
};

export default useAddFolderDialog;