/**
 * This is a modal dialog that popups immediately, presenting the user with a 
 * login button. The login action to perform is given an argument to this 
 * component.
 */
import { useContext, useState } from "react";
import {
    Dialog,
    DialogSurface,
    DialogTitle,
    DialogContent,
    DialogBody,
    DialogActions,
    MessageBar,
    MessageBarTitle,
    MessageBarBody,
    Button,
    makeStyles,
    shorthands,
    Label,
    Input,
    Checkbox,
    Text
} from "@fluentui/react-components";
import { GlobePerson24Regular } from '@fluentui/react-icons';
import { TeamsFxContext } from "../../components/Context";
import { useData } from "@microsoft/teamsfx-react";

const useClasses = makeStyles({
    errorContainer: {
        ...shorthands.margin("10px", "0px"),
    },
});
type LoginDialogProps = {
    onLoginClicked: (loginUserName: string, loginPassword: string, useExternalAuth: boolean) => void,
    errorMessage?: string
}

const useStyles = makeStyles({
    content: {
        display: "flex",
        flexDirection: "column",
        rowGap: "10px",
    },
});

export default function LoginDialog({ onLoginClicked, errorMessage }: LoginDialogProps) {

    const styles = useStyles();
    const classes = useClasses();

    const { teamsUserCredential } = useContext(TeamsFxContext);
    const { loading, data, error } = useData(async () => {
        if (teamsUserCredential) {
            const userInfo = await teamsUserCredential.getUserInfo();
            return userInfo;
        }
    });
    const userName = loading || error ? "" : data!.displayName;

    const [loginUserName, setLoginUserName] = useState("");
    const [loginPassword, setLoginPassword] = useState("");
    const [useExternalAuth, setUseExternalAuth] = useState(false);

    const handleSubmit = (ev: React.FormEvent) => {
        ev.preventDefault();
        onLoginClicked(loginUserName, loginPassword, useExternalAuth);
    }

    return (
        <Dialog modalType="alert" defaultOpen={true}>
            <DialogSurface>
                <form onSubmit={handleSubmit}>
                    <DialogBody>
                        <DialogTitle
                            action={<GlobePerson24Regular />}
                        >Aanmelden</DialogTitle>
                        <DialogContent className={styles.content}>
                            <Text>Hallo <b>{userName}</b>, om deze applicatie te gebruiken moet er eerst worden aangemeld.</Text>
                            <Label htmlFor={"username-input"}>
                                Gebruikersnaam
                            </Label>
                            <Input
                                type="text"
                                id={"username-input"}
                                value={loginUserName}
                                disabled={useExternalAuth}
                                onChange={e => setLoginUserName(e.target.value)}
                            />
                            <Label htmlFor={"password-input"}>
                                Wachtwoord
                            </Label>
                            <Input
                                type="password"
                                id={"password-input"}
                                value={loginPassword}
                                disabled={useExternalAuth}
                                onChange={e => setLoginPassword(e.target.value)}
                            />
                            <Checkbox
                                label="Externe aanmelding gebruiken"
                                checked={useExternalAuth}
                                onChange={e => setUseExternalAuth(e.target.checked)}
                            />
                            {(errorMessage ? (
                                <div className={classes.errorContainer}>
                                    <MessageBar intent={"error"}>
                                        <MessageBarBody>
                                            <MessageBarTitle>Fout bij aanmelden:</MessageBarTitle>
                                            {errorMessage}
                                        </MessageBarBody>
                                    </MessageBar>
                                </div>
                            ) : null)}
                        </DialogContent>
                        <DialogActions>
                            <Button
                                appearance="primary"
                                type="submit"
                                onClick={handleSubmit}
                            >Aanmelden</Button>
                        </DialogActions>
                    </DialogBody>
                </form>
            </DialogSurface>
        </Dialog>
    )
}