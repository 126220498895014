import { useContext } from "react";
import {
    CardHeader,
    Card,
    makeStyles,
    shorthands,
    Caption1,
    tokens,
    Text,
    MenuButton,
    Menu,
    MenuTrigger,
    MenuList,
    MenuItem,
    MenuPopover,
} from "@fluentui/react-components";

import { getFileTypeIconProps, FileIconType } from '@fluentui/react-file-type-icons';
import { MoreHorizontal20Regular } from "@fluentui/react-icons";
import { FolderContextType } from "../../types/Folder";
import { FolderContext } from "../../context/FolderContextProvider";
import { Icon } from '@fluentui/react/lib/Icon';
import { FileStat } from "webdav";

const useStyles = makeStyles({
    container: {
        display: "flex",
        flexWrap: "wrap",
        ...shorthands.padding("16px"),
        ...shorthands.gap("16px"),
    },
    caption: {
        color: tokens.colorNeutralForeground3,
    },
    grid: {
        ...shorthands.gap("16px"),
        display: "flex",
        flexDirection: "column",
    },
    card: {
        width: "300px",
        height: "fit-content",
    },
});

const formatDateTime = (value: string) => {
    const date = new Date(value);
    return date.toLocaleString();
};

type FolderCardProps = {
    file: FileStat
}

export default function FoldersCards() {

    const styles = useStyles();
    const { folder } = useContext(FolderContext) as FolderContextType;

    return (
        <div className={styles.container}>
            {folder.content.filter(item => item.type === 'directory').map(contentItem =>
                <div className={styles.grid} role="list" key={contentItem.basename}>
                    <FolderCard file={contentItem} />
                </div>)
            }
        </div>
    );
}

const FolderCard: React.FC<FolderCardProps> = ({ file: contentItem }) => {

    const styles = useStyles();

    const { updateFolder } = useContext(FolderContext) as FolderContextType;

    return (
        <Card className={styles.card} size="small" role="listitem" appearance="filled-alternative">
            <CardHeader
                image={<Icon {...getFileTypeIconProps({ type: FileIconType.folder, size: 32 })} />}
                header={<Text weight="semibold" onClick={() => updateFolder(contentItem)}>{contentItem.basename}</Text>}
                description={<Caption1>Gewijzigd: {formatDateTime(contentItem.lastmod)}</Caption1>}
                action={
                    <Menu>
                        <MenuTrigger disableButtonEnhancement>
                            <MenuButton appearance="transparent"
                                icon={<MoreHorizontal20Regular />}
                                aria-label="More options"
                            ></MenuButton>
                        </MenuTrigger>

                        <MenuPopover>
                            <MenuList>
                                <MenuItem onClick={(e) => console.log(contentItem.filename, contentItem.basename)}>Create Link</MenuItem>
                            </MenuList>
                        </MenuPopover>
                    </Menu>
                }
            /></Card>
    );
}