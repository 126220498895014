import { useContext, useState, useEffect } from "react";
import {
    Button,
    makeStyles,
    Accordion,
    AccordionHeader,
    AccordionItem,
    AccordionPanel,
    AccordionToggleEventHandler,
} from "@fluentui/react-components";

import {
    bundleIcon,
    ArrowLeft20Filled,
    ArrowLeft20Regular
} from "@fluentui/react-icons";

import { FolderContextType } from "../../types/Folder";
import { FolderContext } from "../../context/FolderContextProvider";
import FoldersCards from "./FoldersCards";
import DocumentsCards from "./DocumentsCards";

const ArrowLeft = bundleIcon(ArrowLeft20Filled, ArrowLeft20Regular);

const useStyles = makeStyles({
    center: {
        "text-align": "center"
    }
});

export default function FolderContent() {

    const styles = useStyles();
    const { folder, setFolderPath } = useContext(FolderContext) as FolderContextType;
    const [openItems, setOpenItems] = useState<string[]>([]);
    const hasSubFolders = folder.content.filter(item => item.type === 'directory').length !== 0;
    const hasDocuments = folder.content.filter(item => item.type === 'file').length !== 0;

    useEffect(() => {
        const initialOpenItems: string[] = [];
        if (hasSubFolders) initialOpenItems.push("1");
        if (hasDocuments) initialOpenItems.push("2");

        setOpenItems(initialOpenItems);
    }, [folder]);

    const handleToggle: AccordionToggleEventHandler<string> = (event, data) => {
        setOpenItems(data.openItems);
    };

    return (
        <>
            {
                folder.loading ? (
                    <div>Loading...</div>
                ) : (
                    folder.content.length > 0 ? (
                        <Accordion
                            openItems={openItems}
                            onToggle={handleToggle}
                            multiple
                            collapsible
                        >
                            <AccordionItem
                                disabled={!hasSubFolders}
                                value="1">
                                <AccordionHeader size="large">Mappen</AccordionHeader>
                                <AccordionPanel>
                                    <FoldersCards />
                                </AccordionPanel>
                            </AccordionItem>
                            <AccordionItem
                                disabled={!hasDocuments}
                                value="2">
                                <AccordionHeader size="large">Documenten</AccordionHeader>
                                <AccordionPanel>
                                    <DocumentsCards />
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    ) : (
                        <div className="folder content">
                            <div className="narrow page-padding">
                                <h2 className={styles.center}>Deze map is leeg...</h2>
                                <p className={styles.center}>
                                    <Button
                                        onClick={() => setFolderPath(folder.path.substring(0, folder.path.lastIndexOf("/")))}
                                        icon={<ArrowLeft />}
                                        aria-label="Terug"
                                        appearance="primary">Terug</Button></p>
                            </div>
                        </div>
                    )
                )
            }
        </>
    )
}