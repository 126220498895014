import {
    useRef,
    useEffect
} from "react";

import {
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    OverlayDrawer,
    Button,
    makeStyles,
    tokens
} from "@fluentui/react-components";
import { 
    Dismiss24Regular,
    Edit24Regular
 } from "@fluentui/react-icons";
import WebViewer from '@pdftron/webviewer'
import { RemoteFile } from "../../types/RemoteFile";

const useStyles = makeStyles({
    container: {
        height: "90vh"
     },
     drawerBody: {
        backgroundColor: tokens.colorNeutralBackground2,
    },
});

type DocumentViewerProps = {
    isOpen: boolean,
    closeViewer: () => void
    file: RemoteFile | null
}

export const DocumentViewer = ({isOpen, closeViewer, file} : DocumentViewerProps) => {

    const styles = useStyles();
    const viewer = useRef(null);

    useEffect(() => {
        if (isOpen) {
            WebViewer.WebComponent(
                {
                    path: `${(process.env.REACT_APP_ROUTER_BASE || "")}/webviewer`,
                    licenseKey: 'demo:1714998832346:7ff7a58d0300000000af0a0053c2dc124ce5db680bddbb05e38c65c172',
                },
                viewer.current,
            ).then((instance) => {
                const { documentViewer } = instance.Core;

                instance.UI.disableElements(['ribbons']);
                instance.UI.setToolbarGroup('toolbarGroup-View');

                instance.UI.loadDocument(file.content, {
                   extension: file.extension,
                   filename: file.file.basename 
                })
            });
        }
    }, [isOpen]);

    return (
        <div>
            <OverlayDrawer
                position="end"
                size="full"
                open={isOpen}
            >
                <DrawerHeader className={styles.drawerBody}>
                    <DrawerHeaderTitle
                        action={
                            <>
                            <Button
                                appearance="subtle"
                                aria-label="Bewerken"
                                icon={<Edit24Regular />}
                                onClick={() => console.log("TODO: bewerken")}
                            />
                            <Button
                                appearance="subtle"
                                aria-label="Close"
                                icon={<Dismiss24Regular />}
                                onClick={closeViewer}
                            />
                            </>
                        }
                    >
                        {file?.file.basename}
                    </DrawerHeaderTitle>
                </DrawerHeader>

                <DrawerBody className={styles.drawerBody}>
                    <div className={styles.container} ref={viewer}/>
                </DrawerBody>
            </OverlayDrawer>
        </div>
    );
};