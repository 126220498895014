import { useContext, useState, useEffect } from "react";
import {
    CardHeader,
    Card,
    makeStyles,
    shorthands,
    Caption1,
    tokens,
    Text,
    MenuButton,
    Menu,
    MenuTrigger,
    MenuList,
    MenuItem,
    MenuPopover,
    CardFooter,
} from "@fluentui/react-components";

import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import {
    bundleIcon,
    MoreHorizontal20Regular,
    ArrowDownload20Regular,
    ArrowDownload20Filled,
    CloudEdit24Filled,
    CloudEdit24Regular,
    PersonEdit24Regular,
    PeopleEdit24Regular,
    Eye24Regular
} from "@fluentui/react-icons";

import { FolderContextType } from "../../types/Folder";
import { FolderContext } from "../../context/FolderContextProvider";
import { Icon } from '@fluentui/react/lib/Icon';
import useFolderProperties from "../../hooks/usePropertiesCard";
import { FileStat } from "webdav";
import { dialog } from "@microsoft/teams-js";
import { TeamsFxContext } from "../../components/Context";
import { formatDateTime, formatSize } from '../../utils/formattingUtils';

const DownloadIcon = bundleIcon(ArrowDownload20Filled, ArrowDownload20Regular);
const EditIcon = bundleIcon(CloudEdit24Filled, CloudEdit24Regular);

type DocumentCardProps = {
    file: FileStat
}
const useStyles = makeStyles({
    container: {
        display: "flex",
        flexWrap: "wrap",
        ...shorthands.padding("16px"),
        ...shorthands.gap("16px"),
    },
    caption: {
        color: tokens.colorNeutralForeground3,
    },
    grid: {
        ...shorthands.gap("16px"),
        display: "flex",
        flexDirection: "column",
    },
    card: {
        width: "300px",
        height: "fit-content",
    },
});


export default function DocumentsCards() {

    const styles = useStyles();
    const { folder } = useContext(FolderContext) as FolderContextType;

    return (
        <div className={styles.container}>
            {folder.content.filter(item => item.type === 'file').map(contentItem =>
                <div className={styles.grid} role="list" key={contentItem.etag}>
                    <DocumentCard file={contentItem} />
                </div>)
            }
        </div>
    )
}

const DocumentCard: React.FC<DocumentCardProps> = ({ file: contentItem }) => {

    const styles = useStyles();

    const [locked, setLocked] = useState(false);
    const [shared, setShared] = useState(false);
    const [editOnline, setEditOnline] = useState(false);
    const { teamsUserCredential } = useContext(TeamsFxContext);

    const { openEditDocumentDialog } = useFolderProperties();
    const { executeDocumentAction, getLockInfo } = useContext(FolderContext) as FolderContextType;

    const onEditDocumentDialogSubmitted = (result: dialog.ISdkResponse) => {
        if (result?.result !== 'canceled') {
            teamsUserCredential.getUserInfo().then(userInfo => {
                getLockInfo(contentItem.filename, userInfo.preferredUserName).then((lockInfo) => {
                    setLocked(lockInfo.locked);
                    setShared(lockInfo.shared);
                })
            })
        }
    }

    useEffect(() => {
        teamsUserCredential.getUserInfo().then(userInfo => {
            getLockInfo(contentItem.filename, userInfo.preferredUserName).then((lockInfo) => {
                setLocked(lockInfo.locked);
                setShared(lockInfo.shared);
                setEditOnline(lockInfo.webUrl !== null || lockInfo.userId == userInfo.preferredUserName || !lockInfo.locked);
            })
        })
    }, []);

    return (
        <Card
            className={styles.card}
            size="small"
            role="listitem"
            appearance="filled-alternative" >
            <CardHeader
                image={<Icon {...getFileTypeIconProps({ extension: contentItem.basename.substring(contentItem.basename.lastIndexOf(".") + 1), size: 32 })} />}
                header={<Text weight="semibold">{contentItem.basename}</Text>}
                description={<Caption1>{formatSize(contentItem.size)} | Gewijzigd: {formatDateTime(contentItem.lastmod)}</Caption1>}
            />
            <CardFooter
                action={
                    <Menu>
                        <MenuTrigger disableButtonEnhancement>
                            <MenuButton appearance="transparent"
                                icon={<MoreHorizontal20Regular />}
                                aria-label="More options" />
                        </MenuTrigger>

                        <MenuPopover>
                            <MenuList>
                                <MenuItem
                                    icon={<Eye24Regular />}
                                    onClick={() => executeDocumentAction(contentItem, "view")}
                                >Bekijken</MenuItem>
                                <MenuItem
                                    icon={<EditIcon />}
                                    disabled={!editOnline}
                                    onClick={() => openEditDocumentDialog(contentItem, onEditDocumentDialogSubmitted)}
                                >Online bewerken</MenuItem>
                                <MenuItem
                                    icon={<DownloadIcon />}
                                    onClick={() => executeDocumentAction(contentItem, "download")}
                                >Downloaden</MenuItem>
                            </MenuList>
                        </MenuPopover>
                    </Menu>
                }>
                {(locked ? (shared ? <PeopleEdit24Regular /> : <PersonEdit24Regular />) : null)}
            </CardFooter>
        </Card>
    )
};
