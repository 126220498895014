import {
    useRef,
} from "react";

import {
    DrawerBody,
    DrawerHeader,
    DrawerHeaderTitle,
    OverlayDrawer,
    Button,
    makeStyles,
    tokens,
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { RemoteFile } from "../../types/RemoteFile";
import PdfViewerComponent from './PdfViewerComponent'

const useStyles = makeStyles({
    container: {
        height: "100%",
    },
    drawerBody: {
        backgroundColor: tokens.colorNeutralBackground2,
    },
});

type DocumentViewerProps = {
    isOpen: boolean,
    closeViewer: () => void
    file: RemoteFile | null
}

export const DocumentViewer2 = ({isOpen, closeViewer, file} : DocumentViewerProps) => {

    const styles = useStyles();
    const viewer = useRef(null);

    return (
        <div>
            <OverlayDrawer
                position="end"
                size="full"
                open={isOpen}
            >
                <DrawerHeader className={styles.drawerBody}>
                    <DrawerHeaderTitle
                        action={
                            <Button
                                appearance="subtle"
                                aria-label="Close"
                                icon={<Dismiss24Regular />}
                                onClick={closeViewer}
                            />
                        }
                    >
                        {file?.file.basename}
                    </DrawerHeaderTitle>
                </DrawerHeader>

                <DrawerBody className={styles.drawerBody}>
                    <PdfViewerComponent content={file?.content}/>
                </DrawerBody>
            </OverlayDrawer>
        </div>
    );
};