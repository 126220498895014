import { useState, useEffect } from 'react';

import {
    app,
    pages
} from "@microsoft/teams-js";

const useDeepLinks = () => {

    const [tenantId, setTenantId] = useState("");
    const appId = process.env.REACT_APP_TEAMS_APP_ID || '762c598b-af77-469a-b08b-7927652c0a7d';

    useEffect(() => {
        app.getContext().then((context) => {
            setTenantId(context.user.tenant.id)
        })
    }, []);

    /**
     * Constructs a deep link for the current context and filename
     * 
     * @param filename the input filename
     * @param displayValue the display name of the link
     * @returns the deep link
     */
    const getDeepLink = async (filename: string, displayValue: string) => {

        const appContext = await app.getContext();
        const entityId = (await pages.getConfig()).entityId;

        const context: any = {
            subEntityId: filename
        }

        if (appContext.channel) {
            context.channelId = appContext.channel.id
        } else if (appContext.chat) {
            context.chatId = appContext.chat.id
        }

        const encodedContext = encodeURIComponent(JSON.stringify(context, null, 2));
        const deeplink = `https://teams.microsoft.com/l/entity/${appId}/${encodeURIComponent(entityId)}?label=${encodeURIComponent(displayValue)}&context=${encodedContext}&tenantId=${tenantId}`;

        return deeplink;
    }

    return {getDeepLink};
}

export default useDeepLinks;