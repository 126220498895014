/**
 * Hook controlling the state of the add document dialog.
 */
import { useState } from 'react';

type UseDialogReturn = {
  isOpen: boolean;
  openAddDocumentsDialog: () => void;
  closeAddDocumentsDialog: () => void;
}

const useAddDocumentsDialog = (): UseDialogReturn => {
  const [isOpen, setIsOpen] = useState(false);

  const openAddDocumentsDialog = () => setIsOpen(true);
  const closeAddDocumentsDialog = () => setIsOpen(false);

  return { isOpen, openAddDocumentsDialog, closeAddDocumentsDialog };
};

export default useAddDocumentsDialog;