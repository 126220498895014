
import {
    MessageBar,
    MessageBarTitle,
    MessageBarBody,
    MessageBarActions,
    Button,
    makeStyles,
    shorthands
} from "@fluentui/react-components";

import {
    FallbackProps
} from "react-error-boundary"

const useClasses = makeStyles({
    container: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        marginLeft: "16px",
        marginRight: "16px",
        marginTop: "16px",
        ...shorthands.gap("10px"),
    },
});

/**
 * The component serving as the fallback component for the error boundary
 * component.
 * 
 * @param props 
 * @returns the error message component
 */
export function BrowserErrorMessage(props: FallbackProps) {

    const { error, resetErrorBoundary } = props;
    const classes = useClasses();

    /**
     * Displays the error message. If the error contains a 401 HTTP status code
     * we assume that the session is no longer valid and the user has to login
     * again.
     */
    return (
        <div className={classes.container}>
            <MessageBar intent="error">
                <MessageBarBody>
                    <MessageBarTitle>Fout:</MessageBarTitle>
                    {error.message}
                </MessageBarBody>
            </MessageBar>
            {( error?.message.indexOf("401") > 0 ? (
                <MessageBar intent={"warning"}>
                <MessageBarBody>
                    <MessageBarTitle>Waarschuwing:</MessageBarTitle>
                    De aanmelding is verlopen, er moet opnieuw worden aangemeld.{" "}
                </MessageBarBody>
                <MessageBarActions>
                    <Button
                        onClick={resetErrorBoundary}
                    >Aanmelden</Button>
                </MessageBarActions>
            </MessageBar>
            ) : null)}
        </div>
    )
}