import { useState } from 'react';

type UseDialogReturn = {
  isOpen: boolean;
  deepLink: string;
  openCopyLinkDialog: (deepLink: string) => void;
  closeCopyLinkDialog: () => void;
}

const useCopyLinkDialog = (): UseDialogReturn => {
  const [isOpen, setIsOpen] = useState(false);
  const [deepLink, setDeepLink] = useState("");

  const openCopyLinkDialog = (deepLink: string) => {
    setDeepLink(deepLink);
    setIsOpen(true);
  }
  const closeCopyLinkDialog = () => setIsOpen(false);

  return { isOpen, deepLink, openCopyLinkDialog, closeCopyLinkDialog };
};

export default useCopyLinkDialog;