import { useEffect, useRef } from "react";

const toolbarItems = new Set();

toolbarItems.add("sidebar-thumbnails");
toolbarItems.add("sidebar-document-outline");
toolbarItems.add("pager");
toolbarItems.add("pan");
toolbarItems.add("zoom-out");
toolbarItems.add("zoom-in");
toolbarItems.add("zoom-mode");
toolbarItems.add("print");
toolbarItems.add("search");
toolbarItems.add("export-pdf");
toolbarItems.add("debug");
toolbarItems.add("spacer");



export default function PdfViewerComponent(props) {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current; // This `useRef` instance will render the PDF.

    let PSPDFKit, instance;

    (async function () {
      PSPDFKit = await import("pspdfkit")

      PSPDFKit.unload(container) // Ensure that there's only one PSPDFKit instance.
      const defaultItems = PSPDFKit.defaultToolbarItems;
      console.log(defaultItems);

      const documentBlobObjectUrl = URL.createObjectURL(props.content);
      const baseUrl = (process.env.PUBLIC_URL || `${window.location.protocol}//${window.location.host}`) + '/'
      instance = await PSPDFKit.load({
//        theme: PSPDFKit.Theme.AUTO,
        // Container where PSPDFKit should be mounted.
        container,
        // The document to open.
        document: documentBlobObjectUrl,
        // Use the public directory URL as a base URL. PSPDFKit will download its library assets from here.
        baseUrl
      }).then(instance => {
        // Make sure to revoke the object URL so the browser doesn't hold on to the blob object that's not needed any more.
        URL.revokeObjectURL(documentBlobObjectUrl);

        const items = instance.toolbarItems;
        instance.setToolbarItems(items.filter(item => toolbarItems.has(item.type)));

      });


    })();

    return () => PSPDFKit && PSPDFKit.unload(container)
  }, []);

  // This div element will render the document to the DOM.
  return <div ref={containerRef} style={{ width: "100%", height: "100%" }} />
}