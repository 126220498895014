import { useEffect, useState } from 'react';

import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent,
    Button,
    Text,
    useId,
    makeStyles,
    Label,
    Input,
    InputProps
} from "@fluentui/react-components";

const useStyles = makeStyles({
    content: {
        display: "flex",
        flexDirection: "column",
        rowGap: "10px",
    },
});

interface CopyLinkDialogProps {
    isOpen: boolean;
    label: string;
    deepLink: string;
    onClose: () => void;
}

export default function CopyLinkDialog({ isOpen, onClose, label, deepLink }: CopyLinkDialogProps) {

    const styles = useStyles();

    const [displayValue, setDisplayValue] = useState(label);
    const inputId = useId("input");

    useEffect(() => {
        setDisplayValue(label);
    }, [label]);

    /**
     * Creates a link and copies both text/plain and text/html data to the 
     * clipboard so different applications can use whichever format they need.
     */
    const onCopyLink = async () => {

        const html = `<a href="${deepLink}">${displayValue}</a>`;

        const clipboardItem = new ClipboardItem({
            'text/plain': new Blob([deepLink], { type: 'text/plain' }),
            'text/html': new Blob([html], { type: 'text/html' }),
        });

        navigator.clipboard.write([clipboardItem]);
        setDisplayValue(label);
        onClose();
    }

    /**
     * Update the display value
     * 
     * @param _ 
     * @param data 
     */
    const onChange: InputProps["onChange"] = (_, data) => {
        setDisplayValue(data.value);
    }

    return (
        <Dialog open={isOpen}>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>Kopieer een link naar deze locatie</DialogTitle>
                    <DialogContent className={styles.content}>
                        <Label required htmlFor={inputId}>
                            Weergavenaam
                        </Label>
                        <Input required type='text' value={displayValue} id={inputId} onChange={onChange} autoFocus={true} />
                        <Text><p>Deze link kan geplakt worden in een chat of een kanaal.</p></Text>
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary" onClick={() => { setDisplayValue(label); onClose() }}>Sluiten</Button>
                        </DialogTrigger>
                        <Button appearance="primary" onClick={onCopyLink} disabled={displayValue?.length === 0}>Kopiëren</Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};