import {
  useContext,
} from "react";

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbDivider,
  BreadcrumbButton,
} from "@fluentui/react-components";
import {
  Folder32Filled,
  Folder32Regular,
  Home32Filled,
  Home32Regular,
  bundleIcon,
} from "@fluentui/react-icons";

import { FolderContext } from "../../context/FolderContextProvider";
import { FolderContextType } from "../../types/Folder";

const FolderIcon = bundleIcon(Folder32Filled, Folder32Regular);
const HomeIcon = bundleIcon(Home32Filled, Home32Regular);

type FoldersBreadcrumbProps = {
  rootPath: string
}

export default function FoldersBreadcrumb({ rootPath }: FoldersBreadcrumbProps) {

  const { folder, setFolderPath } = useContext(FolderContext) as FolderContextType;

  const relativePath = rootPath.substring(0, rootPath.lastIndexOf('/'));

  /*
   * Credits for parsing the path: https://cheatcode.co/tutorials/how-to-build-dynamic-breadcrumbs-from-a-url-path
   */

  let pathParts = folder.path.replace(relativePath, "").split('/').filter((part) => part?.trim() !== '');
  let pathItems = pathParts?.map((part, partIndex) => {
    const previousParts = pathParts.slice(0, partIndex);
    return {
      label: part,
      path: previousParts?.length > 0 ? `/${previousParts?.join('/')}/${part}` : `/${part}`,
      isCurrent: partIndex === pathParts.length - 1,
      isHome: partIndex === 0
    };
  }) || [];

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
      <Breadcrumb size="large" aria-label="Breadcrumb default example">

        {pathItems.map(pathItem =>
          <>
            <BreadcrumbItem>
              <BreadcrumbButton
                aria-label={"pathItem.label"}
                onClick={() => setFolderPath(`${relativePath}${pathItem.path}`)}
                current={pathItem.isCurrent}
                icon={pathItem.isHome ? <HomeIcon /> : <FolderIcon />}>{pathItem.label}</BreadcrumbButton>
            </BreadcrumbItem>
            {
              !pathItem.isCurrent ? (
                <BreadcrumbDivider />
              ) : null
            }
          </>
        )}
      </Breadcrumb>
    </div>
  );
};